import antd from 'ant-design-vue/es/locale-provider/zh_CN'
import momentCN from 'moment/locale/zh-cn'

const components = {
  antLocale: antd,
  momentName: 'zh-cn',
  momentLocale: momentCN
}

const locale = {
  message: '-',
  'day': '天',
  'field.value': '',
  'button.action': '操作',
  'button.approval': '审批',
  'button.putOn': '上架',
  'button.publish': '发布',
  'button.pause': '暂停',
  'button.restore': '恢复',
  'button.reset': '重置',
  'button.collapseOut': '展开',
  'button.online': '上线',
  'button.collapseIn': '收起',
  'button.search': '查询',
  'button.pullOff': '下架',
  'button.enable': '生效',
  'button.disable': '失效',
  'button.delete': '删除',
  'button.edit': '编辑',
  'button.lock': '锁定',
  'button.unlock': '解锁',
  'button.close': '关闭',
  'button.complete': '完成',
  'button.submit': '提交',
  'button.preview': '预览',
  'button.select': '选择',
  'button.cancel': '取消',
  'button.reject': '拒绝',
  'button.add': '添加',
  'button.readFromFile': '从文件读取',
  'button.create': '新建',
  'button.batchCreate': '批量创建',
  'button.import': '导入',
  'button.export': '导出',
  'button.exportLogic': '导出出货订单',
  'button.exportWmsLogic': '导出wms出货订单',
  'button.importLogic': '导入物流信息',
  'button.exportMode': '物流信息导入模板下载',
  'button.detail': '详情',
  'button.pending': '设为【待处理】',
  'button.decided': '设为【已完成】',
  'button.canceled': '设为【已取消】',
  'button.refund': '退款',
  'button.save': '保存',
  'menu.home': '主页',
  'menu.dashboard': '仪表盘',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'module.order': '订单管理',
  'module.shipping': '物流管理',
  'module.member': '会员管理',
  'module.system': '基础配置',
  'module.marketing': '营销管理',
  'module.pay': '支付管理',
  'module.activity': '拼团管理',
  'module.goods': '商品管理',
  'module.goodsSetting': '商品设定',
  'module.goodsDisplay': '商品展示',
  'module.stock': '库存管理',
  'module.pricing': '定价管理',
  'module.cms': '页面设计',
  'menu.other.permission': '权限设定',
  'menu.other.user-list': '用户管理',
  'menu.other.role-list': '角色管理',
  'menu.other.permission-list': '权限列表',
  'menu.account.setting': '个人设定',
  'menu.account.setting.center': '个人中心',
  'menu.account.setting.base': '基本设置',
  'menu.account.setting.security': '安全设定',
  'menu.account.setting.custom': '个性化设置',
  'menu.account.setting.binding': '账户绑定',
  'menu.account.setting.notification': '新消息通知',
  'message.confirm.delete': '确认要删除吗？',
  'message.information': '提示',
  'message.upload': '上传',
  'message.uploadPic': '上传图片',
  'entity.unit.get.fail': '获取【单位】数据失败',
  'entity.unit.save.success': '保存【单位】成功',
  'entity.unit.save.fail': '保存【单位】失败',
  'entity.unit.delete.success': '删除【单位】成功',
  'entity.unit.delete.fail': '删除【单位】失败',
  'entity.unit.enable.success': '生效【单位】成功',
  'entity.unit.enable.fail': '生效【单位】失败',
  'entity.unit.disable.success': '失效【单位】成功',
  'entity.unit.disable.fail': '失效【单位】失败',
  'entity.brand.save.success': '保存【品牌】成功',
  'page.phonePreview': '效果预览',
  'page.approvalEditor': '请输入审批信息',
  'page.commentEditor': '请输入备注信息',
  'page.cancelOprate': '请输入取消原因',
  'page.serviceOrderManage': '售后单管理',
  'page.saleOrderHistoryManage': '订单历史',
  'page.serviceOrderHistoryManage': '售后单历史',
  'page.saleOrderManage': '订单管理',
  'page.saleOrderShippingInfo': '订单物流信息',
  'page.activityHistoryManage': '活动历史',
  'page.activityManage': '活动管理',
  'page.memberHistoryManage': '会员历史',
  'page.memberGradeManage': '会员等级管理',
  'page.memberManage': '会员管理',
  'page.shippingAddressManage': '会员收货地址管理',
  'page.transferFundHistoryManage': '转账记录历史',
  'page.transferFundManage': '转账记录管理',
  'page.withdrawBillHistoryManage': '提款单历史',
  'page.withdrawBillManage': '提款单管理',
  'page.deliveryCompanyManage': '物流公司管理',
  'page.shippingBillManage': '物流单管理',
  'page.pickupStockManage': '自提点管理',
  'page.customerShippingPackageManage': '用户包裹管理',
  'page.customerShippingGroupPackageManage': '用户合并包裹管理',
  'page.shippingBillHistoryManage': '物流单历史',
  'page.customerReturnPackageManage': '客户退回包裹管理',
  'page.appVersionManage': 'App版本管理',
  'page.appManage': 'App管理',
  'page.supplierCompanyManage': '供应商管理',
  'page.marketplaceManage': '市场管理',
  'page.siteManage': '站点管理',
  'page.homeTemplateManage': '首页模板管理',
  'page.homeTemplateDisplayManage': '首页模板展示管理',
  'page.warehouseManage': '库房管理',
  'page.afterSaleServiceManage': '售后服务管理',
  'page.supplierWarehouseManage': '供应商仓库管理',
  'page.multilingualInfo': '多语言信息',
  'page.couponTypeManage': '优惠券类型管理',
  'page.couponManage': '优惠券模板管理',
  'page.campaignTypeManage': '促销活动类型管理',
  'page.campaignManage': '促销活动管理',
  'page.skuTagManage': 'SKU标签管理',
  'page.skuPriceHistoryManage': 'SKU定价历史',
  'page.skuStockWarehouseHistoryManage': '库房库存历史',
  'page.spuManage': 'SPU管理',
  'page.skuManage': 'SKU管理',
  'page.skuPriceManage': 'SKU定价管理',
  'page.skuOnOffHistoryManage': 'SKU上下架历史',
  'page.categoryManage': '管理分类',
  'page.unitManage': '单位管理',
  'page.skuServiceManage': 'SKU商品服务',
  'page.skuOnOffManage': 'SKU上下架管理',
  'page.skuAttrManage': 'SKU属性管理',
  'page.displayCategoryManage': '显示分类管理',
  'page.displayTemplateManage': '商品详情展示模板管理',
  'page.skuStockManage': 'SKU库存管理',
  'page.skuReviewManage': 'SKU评价管理',
  'page.skuStockMarketplaceHistoryManage': '市场库存历史',
  'page.brandManage': '品牌管理',
  'page.skuAttrTemplateManage': 'SKU属性模板管理',
  'page.spuTagManage': 'SPU标签管理',
  'page.skuDisplayList': 'SKU多语言展示管理',
  'title.skuDisplayList': 'SPU多语言展示管理',
  'title.picList': '主图列表',
  'title.preview': '预览',
  'title.bannerSetting': '轮播图',
  'title.detailPicDisplayList': '详情图列表',
  'page.SpuDisplayEdit': 'SPU多语言展示编辑',
  'page.SkuDisplayDetail': 'SKU多语言展示详情',
  'page.SkuEdit': 'SKU编辑',
  'page.SpuDetail': 'SPU详情',
  'page.SkuDisplayEdit': 'SKU多语言展示编辑',
  'title.SkuDetail': 'Sku详情',
  'page.SkuDetail': 'Sku详情',
  'page.SpuEdit': 'SPU编辑',
  'page.spuDisplayList': 'SPU多语言展示管理',
  'title.languageLabelList': '语言标签列表',
  'title.spuDisplayList': 'SPU多语言展示管理',
  'title.saleOrderHistoryList': '订单历史管理',
  'title.languageList': '语言列表',
  'title.attrDisplayList': '属性列表',
  'title.saleAttrDisplayList': '销售属性列表',
  'title.serviceDisplayList': '服务列表',
  'title.topPicDisplayList': '置顶可关闭图',
  'page.bannerList': '轮播图',
  'title.bannerList': '轮播图',
  'page.coupon': '优惠券',
  'title.couponList': '优惠券列表',
  'title.spuList': 'SPU列表',
  'title.skuList': 'SKU列表',
  'title.selectIndicator': '选择指示器',
  'page.campaign': '促销活动',
  'title.campaignList': '促销活动列表',
  'page.campaignType': '促销活动类型',
  'page.couponType': '优惠券类型',
  'page.refund': '退款',
  'title.topMessageList': '置顶消息提示列表',
  'title.topPicList': '置顶可关闭图片提示列表',
  'page.topMessageList': '置顶消息提示',
  'page.topPicList': '置顶可关闭图片提示',
  'button.moveUp': '上移',
  'button.moveDown': '下移',
  'title.attrSetting': '属性设定',
  'title.picSetting': '图片设定',
  'title.basicInfo': '基本信息',
  'title.conditionSetting': '条件设定',
  'title.couponSetting': '优惠券设定',
  'title.campaignSetting': '营销活动设定',
  'title.goodsSetting': '商品活动设定',
  'title.goodsShow': '商品展示',
  'title.multilingual': '多语言设定',
  'title.discountSetting': '优惠设定',
  'title.noticeSetting': '通知设定',
  'title.approvalHistoryList': '审批历史',
  'msg.confirmDelete': '确定要删除吗 ?'
}

const fields = {
  'importComment': '导入备注',
  'aboutUsPageUrl': '关于我们H5页面地址',
  'approveTime': '审批时间',
  'isPutOn': '已上架',
  'putOnQuantity': '上架数量',
  'isFirstSku': '是否默认Sku',
  'saleAttrDisplayList': '销售属性列表',
  'showText': '显示文字',
  'marketplace': '市场',
  'site': '站点',
  'linkParamList': '链接参数列表',
  'spuUnit': '单位',
  'tagPriceShow': '标牌价',
  'approvalStatus': '审批状态',
  'serviceDisplayList': '服务显示列表',
  'language': '语言',
  'couponTypeCode': '优惠券类型编码',
  'couponTypeName': '优惠券类型名称',
  'memberId': '会员ID',
  'billNo': '单据号',
  'searchKeyword': '搜索关键词',
  'deliveryOrderNo': '快递单编号',
  'tagId': '标签ID',
  'tagName': '标签名称',
  'marketplaceOnOff': '市场上下架',
  'deliveryCompany': '快递公司',
  'deliveryCompanyCode': '快递公司编码',
  'deliveryCompanyName': '快递公司名称',
  'deliveryCompanyPhone': '电话',
  'spuName': 'SPU名称',
  'spuIntro': '商品介绍',
  'unit': '单位',
  'unitCode': '单位编码',
  'spuNo': 'SPU编码',
  'listPicUrl': '列表图片',
  'picList': '主图列表',
  'detailPicList': '详细介绍图片列表',
  'detailPicDisplayList': '详细介绍图片列表',
  'videoList': '视频列表',
  'categoryId': '管理分类ID',
  'categoryName': '管理分类名称',
  'attrTemplateId': '属性模板ID',
  'status': '状态',
  'statusDesc': '状态描述',
  'indicatorColor': '指示器颜色',
  'onOffStatus': '状态',
  'attrList': '属性列表',
  'attrLabel': '属性名标签',
  'attrName': '属性名称',
  'attrKey': '属性Key',
  'attrCode': '属性编码',
  'attrValueList': '属性值列表',
  'valueName': '值名称',
  'valueKey': '属性值Key',
  'valueCode': '属性值编码',
  'attrValueDeleteFlag': '属性值删除标记',
  'attrValueId': '属性值ID',
  'attrDeleteFlag': '属性删除标记',
  'attrId': '属性ID',
  'campaignTypeCode': '促销类型编码',
  'campaignTypeName': '促销类型名称',
  'campaignCode': '促销编码',
  'campaignName': '促销名称',
  'campaignIntro': '促销说明',
  'displayName': '显示名称',
  'displayIntro': '显示说明',
  'startTime': '开始时间',
  'endTime': '结束时间',
  'createTime': '创建时间',
  'updateTime': '更新时间',
  'condition': '适用条件',
  'categoryCode': '管理分类编码',
  'brandCode': '品牌编码',
  'memberGradeId': '会员级别ID',
  'spuNoList': 'SPU编号列表',
  'skuNoList': 'SKU编号列表',
  'discount': '折扣信息',
  'publishTime': '发布时间',
  'offlineTime': '下线时间',
  'onlineTime': '上线时间',
  'onlineStatus': '上线状态',
  'onlineComment': '上线备注',
  'pricePercent': '价格百分比',
  'shippingFeeAmountPercent': '运费百分比',
  'priceAmount': '价格金额',
  'discountValue': '优惠数额',
  'timeLimitType': '时间期限类型',
  'shippingFeeAmount': '运费金额',
  'userName': '用户名',
  'validDateCount': '有效天数',
  'minTotalAmount': '最小总金额',
  'maxTotalAmount': '最大总金额',
  'minTotalQuantity': '最小总数量',
  'maxTotalQuantity': '最大总数量',
  'newUserPromptText': '新用户提示语',
  'logisticsModelPickup': '自提',
  'logisticsModelShipping': '快递',
  'logisticsModel': '物流类型',
  'validTimeRange': '有效期范围',
  'h5PageUrl': 'H5页面地址',
  'payMethodPaypal': 'Paypal支付',
  'payMethodCreditCard': '信用卡支付',
  'payMethodType': '支付方式',
  'payBizType': '支付业务类型',
  'memberName': '会员姓名',
  'bankAccountName': '银行账号名',
  'bankAccountNo': '银行账号',
  'opType': '操作类型',
  'amount': '金额',
  'transferTime': '转账时间',
  'transferNo': '转账单号',
  'transferMemo': '转账备注',
  'transferAmount': '转账金额',
  'orderId': '订单ID',
  'deliveryId': '运单ID',
  'orderStatus': '订单状态',
  'orderStatusDesc': '订单状态描述',
  'shippingStatus': '物流状态',
  'shippingStatusDesc': '物流状态说明',
  'payStatus': '支付状态',
  'payStatusDesc': '支付状态说明',
  'activityId': '拼单活动ID',
  'activityName': '拼单活动名称',
  'orderSubmitTime': '订单提交时间',
  'memberNickName': '会员昵称',
  'memberAvatarUrl': '会员照片',
  'dealAmount': '订单金额',
  'totalAmount': '订单总金额',
  'totalPayAmount': '总付款金额',
  'discountAmount': '优惠金额',
  'payAmount': '支付金额',
  'shippingFee': '运费',
  'dealAmountShow': '订单金额',
  'totalAmountShow': '订单总金额',
  'discountAmountShow': '优惠金额',
  'payAmountShow': '支付金额',
  'shippingFeeShow': '运费',
  'totalQuantity': '总数量',
  'logisticsModelDesc': '物流类型说明',
  'payMethodTypeDesc': '支付方式说明',
  'isSelfDelivery': '是否自提',
  'logisticsInfo': '物流信息',
  'packageId': '包裹ID',
  'shippingHistory': '物流历史',
  'pickupStock': '自提点',
  'pickupStockName': '自提点名称',
  'contactName': '联系人',
  'contactPhone': '联系人电话',
  'contactEmail': '联系人邮箱',
  'fullAddress': '详细地址',
  'orderReceiverInfo': '收货人信息',
  'receiverName': '收货人姓名',
  'receiverPhone': '收件人电话',
  'activityInfo': '活动详情',
  'memberCount': '团员数量',
  'completeTime': '完成时间',
  'orderItemList': '订单商品列表',
  'skuNo': 'SKU编号',
  'skuImage': 'SKU图片',
  'skuName': 'SKU名称',
  'quantity': '数量',
  'originalPrice': '原价',
  'dealPrice': '交易价格',
  'originalPriceShow': '原价',
  'dealPriceShow': '交易价格',
  'tags': '标签',
  'saleAttrList': '销售属性列表',
  'saleAttrName': '销售属性名称',
  'saleAttrValueList': '销售属性值列表',
  'saleAttrValueDeleteFlag': '销售属性删除标志',
  'saleAttrValueId': '销售属性值ID',
  'saleAttrDeleteFlag': '销售属性删除标志',
  'saleAttrId': '销售属性ID',
  'isSlaveOrder': '订单种类',
  'channel': '支付渠道',
  'message': '内容',
  'remarkNo': '序号',
  'remarkName': '备注人',
  'remarkTime': '备注时间',
  'payBillNo': '支付流水号',
  'payBillStatus': '支付状态',
  'paidTime': '支付时间',
  'skuIntro': 'SKU信息',
  'templateId': '模板ID',
  'marketplacePrice': '市场价格',
  'marketplaceStock': '市场库存',
  'marketplaceCode': '市场编码',
  'marketplaceName': '市场名称',
  'country': '国家',
  'tagPrice': '标牌价',
  'salePrice': '销售价',
  'activityDesc': '活动描述',
  'activityStatus': '活动状态',
  'activityStatusDesc': '活动状态说明',
  'leaderId': '团长ID',
  'leaderAccount': '团长账号',
  'leaderNickName': '团长昵称',
  'leaderAvatar': '团长头像',
  'leaderPhone': '团长电话',
  'memberLimitMin': '最少会员数',
  'memberLimitMax': '最多会员数',
  'memberList': '会员列表',
  'memberManage': '会员管理',
  'userId': '用户ID',
  'userAccount': '用户账号',
  'userAvatar': '用户头像',
  'timeTag': '时间标签',
  'logisticModel': '物流信息',
  'desc': '说明',
  'payMethodTypeList': '支付方式',
  'pickupStockList': '自提点一览',
  'pickupStockManage': '自提点管理',
  'pickupStockId': '自提点ID',
  'address1': '地址1',
  'address2': '地址2',
  'address3': '地址3',
  'addressDetail': '详细地址',
  'state': '州/省',
  'room': 'room',
  'house': 'house',
  'street': 'street',
  'leaderName': '团长名称',
  'memberAccount': '会员账号',
  'memberPhone': '会员电话',
  'skuUnit': 'SKU单位',
  'totalStock': '总库存',
  'useStock': '使用库存',
  'lang': '语言',
  'url': 'URL',
  'size': '大小',
  'color': '颜色',
  'weight': '重量',
  'width': '宽度',
  'height': '高度',
  'depth': '深度',
  'packageWidth': '包装宽度',
  'packageWeight': '包装重量',
  'packageHeight': '包装高度',
  'packageDepth': '包装深度',
  'owner': '所有人',
  'sortOrder': '排序编号',
  'parentCategoryCode': '上级分类ID',
  'unitId': '单位ID',
  'unitName': '单位名称',
  'unitValue': '单位可选值',
  'label': '显示标签',
  'keyword': '关键词',
  'maxPrice': '最大价格',
  'minPrice': '最小价格',
  'maxStockCount': '最大库存数量',
  'minStockCount': '最小库存数量',
  'attrNameKeyword': '属性名称关键词',
  'appCode': 'APP编码',
  'appName': 'APP名称',
  'appVersion': 'APP版本',
  'needUpdate': '需要强制更新',
  'platformCode': '平台编码',
  'marketplaceList': '市场列表',
  'platformName': '平台名称',
  'platformVersion': '平台版本',
  'siteCode': '站点编码',
  'siteName': '站点名称',
  'siteList': '站点列表',
  // 'marketingConditionType': '促销条件类型',
  'marketingConditionType': '优惠券条件类型',
  // 'marketingDiscountType': '促销优惠类型',
  'marketingDiscountType': '优惠券类型',
  'appStoreName': '应用市场名称',
  'supplierCompanyCode': '供应商公司编码',
  'supplierCompanyName': '供应商公司名称',
  'supplierCompanyPhone': '供应商公司电话',
  'supplierCompanyContactName': '供应商公司联系人',
  'supplierCompanyContactPhone': '供应商公司联系人电话',
  'memberGradeName': '会员等级名称',
  'attrValue': '属性可选值',
  'valueLabel': '值标签',
  'phone': '电话',
  'mail': '邮箱',
  'isLeader': '是否团长',
  'displayCategoryId': '显示分类ID',
  'displayCategoryCode': '显示分类编码',
  'displayCategoryName': '显示分类名称',
  'displayCategoryLabel': '显示分类标签',
  'parentDisplayCategoryCode': '上级显示分类编码',
  'couponCode': '优惠券码',
  'couponName': '优惠券名称',
  'couponIntro': '优惠券说明',
  'shippingAddress': '物流地址',
  'template': '模板',
  'boundQuantity': '绑定数量',
  'warehouseStock': '仓库库存',
  'warehouseCode': '仓库编码',
  'warehouseName': '仓库名称',
  'warehouseAddress': '仓库地址',
  'warehouseContactName': '仓库联系人',
  'warehouseContactPhone': '仓库联系人电话',
  'shippingAddressId': '物流地址ID',
  'shippingAddressName': '物流地址标题',
  'spuStatus': 'SPU状态',
  'campaignStatus': '促销状态',
  'billStatus': '单据状态',
  'skuStatus': 'SKU状态',
  'memberAvatar': '会员头像',
  'couponStatus': '优惠券状态',
  'parentCategoryId': '父级分类ID',
  'pageNo': '页码',
  'ok': 'OK',
  'skuTagId': 'SKU标签',
  'skuTagName': 'SKU标签',
  'spuTagId': 'SPU标签',
  'spuTagName': 'SPU标签',
  'isValid': '是否有效',
  'creatorId': '创建人ID',
  'creatorCode': '创建人编码',
  'creatorName': '创建人',
  'updaterId': '更新人ID',
  'updaterCode': '更新人编码',
  'updaterName': '更新人',
  'skuPriceHistoryId': 'SKU价格历史ID',
  'marketplaceCodes': '市场编码',
  'oldmarketplaceCode': '市场编码',
  'collectionFlag': '集合标记',
  'sitecodeList': '站点编码列表',
  'dataPermission': '数据权限',
  'pageId': '页面ID',
  'pageName': '页面名称',
  'pageType': '页面类型',
  'backgroundColor': '背景颜色',
  'floorList': '楼层列表',
  'moduleId': '模块ID',
  'moduleName': '模块名称',
  'imageList': '图片一览',
  'imagePageId': '图片页面ID',
  'imageLink': '图片链接',
  'imageLinkType': '图片链接类型',
  'imageCategoryId': '图片分类ID',
  'imageSkuId': '图片SKUID',
  'moduleMoreLink': '显示更多链接',
  'moduleMoreLinkType': '显示更多链接类型',
  'moduleMorePageId': '更多页面ID',
  'moduleMoreCategoryId': '更多分类ID',
  'imageUrl': '图片URL',
  'index': '索引',
  'hideMargin': '隐藏边距',
  'showModule': '显示模块',
  'haveModuleData': '独立定义模块数据',
  'pageTemplateId': '页面模板ID',
  'categoryFloorEnterList': '品类楼层进入列表',
  'enterType': '进入类型',
  'categoryImageUrl': '品类图片',
  'buttonName': '按钮名称',
  'link': '链接',
  'likeType': '喜欢类型',
  'appId': '应用ID',
  'goodsModule': '商品模块',
  'isShowPromoList': '是否显示促销一览',
  'moduleShowName': '模块显示名称',
  'skuList': '商品一览',
  'skuManage': '商品管理',
  'skuId': 'SKUID',
  'advertising': '广告',
  'oldPrice': '旧价格',
  'price': '价格',
  'specifications': '规格',
  'promoList': '促销列表',
  'maxNum': '最大数量',
  'moduleMoreShowName': '模块显示更多名称',
  'felevator': '电梯',
  'backgroundImageUrl': '背景图片',
  'felevatorType': '电梯类型',
  'felevatorContentList': '电梯内容列表',
  'name': '名称',
  'felevatorName': '电梯名称',
  'isShow': '是否显示',
  'defineGoodsFloor': '定义商品楼层',
  'chooseType': '选择类型',
  'categoryShowList': '品类显示列表',
  'showGoodsCount': '显示商品数量',
  'asc': '正序排列',
  'defaultTemplate': '默认模板',
  'areaImage': '区域图片',
  'areaModuleList': '图片模块列表',
  'coords': '坐标',
  'defineGoodsTemplate': '定义商品模板',
  'isShowMore': '是否显示更多',
  'isDefaultData': '是否默认数据',
  'collectionFlagName': '集合标志',
  'isForceUpdate': '是否强制更新',
  'updateDescription': '更新说明',
  'forceUpdateDescription': '强制更新说明',
  'appFileUrl': 'APP文件下载地址',
  'releaseDate': '发布日期',
  'fileSize': '文件大小',
  'barcode': '商品条码',
  'qrcode': '商品二维码',
  'skuPriceId': 'SKU价格ID',
  'skuOnOffHistoryId': 'SKU上下架历史ID',
  'city': '城市',
  'registerType': '注册类型',
  'iconUrl': '图标URL',
  'minQuantity': '安全库存数量',
  'goodsTemplate': '商品模板',
  'orderCount': '订单数量',
  'packageCount': '包裹数量',
  'lowRegulations': '法规',
  'material': '材质',
  'serviceList': '服务一览',
  'serviceId': '服务ID',
  'serviceCode': '服务编码',
  'serviceType': '服务类型',
  'serviceName': '服务名称',
  'serviceDays': '时间限制',
  'appStoreAccount': 'AppStore账号',
  'playStoreAccount': 'PlayStore账号',
  'iosDeviceNo': 'IOS设备编号',
  'androidDeviceNo': '安卓设备编号',
  'browserDeviceNo': '浏览器设备编号',
  'iosAdvertiseId': 'ios广告ID',
  'androidAdvertiseId': '安卓广告ID',
  'browserAdvertiseId': '浏览器广告ID',
  'totalDiscountAmount': '优惠总金额',
  'totalOrderCount': '订单总数量',
  'totalGroupByCount': '参团总数量',
  'totalGroupCount': '拼团活动总数量',
  'stars': '星级',
  'deleteReason': '删除原因',
  'reviewComment': '评论内容',
  'comment': '评论内容',
  'quantityChange': '数量变化',
  'quantityChanged': '数量变化',
  'serviceOrderId': '数量变化',
  'serviceOrderStatus': '售后单状态',
  'serviceOrderStatusDesc': '售后单状态描述',
  'serviceOrderSubmitTime': '售后单提交时间',
  'serviceOrderReturnShippingAddress': '客户寄回地址',
  'serviceOrderResendShippingAddress': '重发包裹收货地址',
  'serviceOrderItemList': '售后单项目',
  'saleOrderId': '订单ID',
  'saleOrderStatus': '订单状态',
  'saleOrderCount': '订单数量',
  'saleOrderStatusDesc': '订单状态描述',
  'saleOrderSubmitTime': '订单提交时间',
  'saleOrderItemList': '订单项目列表',
  'goodsPayAmount': '商品付款金额',
  'shippingPayAmount': '邮费付款金额',
  'goodsTotalAmount': '商品总金额',
  'reciveName': '收件人',
  'reciveContact': '收件人联系方式',
  'reciveArea': '收件人区域',
  'reciveAddr': '收件人地址',
  'totalSaleOrderCount': '订单总数',
  'totalServiceOrderCount': '售后单总数',
  'saleOrderShippingAddress': '订单收货地址',
  'serviceOrderList': '售后单一览',
  'serviceOrderNo': '售后单号',
  'deliveryCompanyId': '物流公司ID',
  'skuStockWarehouseHistoryId': 'SKU库存库房调整ID',
  'appVersionId': 'APP版本ID',
  'activityCode': '活动编码',
  'skuOnOffId': 'SKU上下架ID',
  'saleOrderNo': '订单编号',
  'skuServiceId': 'SKU服务ID',
  'pickupStockCode': '自提点编码',
  'supplierCompanyId': '供应商公司ID',
  'memberGradeCode': '会员级别编码',
  'couponId': '优惠券ID',
  'displayTemplateId': '商品详情展示模板ID',
  'displayTemplateName': '商品详情展示模板名称',
  'skuStockId': 'SKU库存ID',
  'skuReviewId': 'SKU评价ID',
  'warehouseId': '仓库ID',
  'skuStockMarketplaceHistoryId': 'SKU库存市场调整ID',
  'skuAttrTemplateId': 'SKU属性模板ID',
  'attrTemplate': '属性模板',
  'skuAttrTemplateName': 'SKU属性模板名称',
  'supplierWarehouseId': '供应商库房ID',
  'campaignId': '促销活动ID',
  'shippingBillNo': '运单编号',
  'memberInfo': '会员信息',
  'shippingInfo': '物流信息',
  'brandName': '品牌名称',
  'customerShippingPackageId': '客户发货包裹ID',
  'customerShippingPackageNo': '客户发货包裹编号',
  'packageTime': '打包时间',
  'shippingTime': '发货时间',
  'arrivalTime': '签收时间',
  'packageItemList': '包裹项目列表',
  'leaderInfo': '团长信息',
  'shippingGroupPackageId': '客户发货合并包裹ID',
  'shippingGroupPackageNo': '客户发货合并包裹编号',
  'subPackageList': '子包裹列表',
  'shippingBillId': '运单ID',
  'singlePackage': '单包裹',
  'groupPackage': '合并包裹',
  'customerShipping': '正向发货',
  'customerReturn': '客户返件',
  'shippingBillType': '运单类型',
  'contentInfo': '内容物信息',
  'contentType': '内容物类型',
  'shippingPackageId': '物流包裹ID',
  'shippingPackageNo': '物流包裹编号',
  'warehouseInfo': '库房信息',
  'pickerId': '拣货人ID',
  'pickerCode': '拣货人编码',
  'pickerName': '拣货人',
  'checkerId': '复查人ID',
  'checkerCode': '复查人编码',
  'checkerName': '复查人',
  'packagerId': '打包人ID',
  'packagerCode': '打包人编码',
  'packagerName': '打包人',
  'shipperId': '寄件人ID',
  'shipperCode': '寄件人编码',
  'shipperName': '寄件人',
  'printerId': '打单人ID',
  'printerCode': '打单人编码',
  'printerName': '打单人',
  'shippingStage': '物流阶段',
  'customerReturnPackageId': '客户返件包裹ID',
  'customerReturnPackageNo': '客户返件包裹编号',
  'memberStatus': '会员状态',
  'createTimeRange': '创建时间范围',
  'transferFundId': '汇款单ID',
  'marketplaceId': '市场ID',
  'siteId': '站点ID',
  'afterSaleServiceId': '售后服务ID',
  'afterSaleServiceCode': '售后服务编码',
  'afterSaleServiceType': '售后服务类型',
  'afterSaleServiceName': '售后服务名称',
  'afterSaleServiceDays': '售后服务日期限制',
  'campaignTypeId': '促销类型ID',
  'spuId': 'SPUID',
  'brandId': '品牌ID',
  'skuAttrId': 'SKU属性ID',
  'pickupStockInfo': '自提点信息',
  'shippingAddressInfo': '收货地址信息',
  'customerShippingGroupPackageId': '顾客收货包裹ID',
  'customerShippingGroupPackageNo': '顾客收货合并包裹ID',
  'saleOrderHistoryId': '订单历史ID',
  'title': '标题',
  'content': '内容',
  'serviceOrderHistoryId': '售后单历史ID',
  'activityHistoryId': '活动历史ID',
  'memberHistoryId': '会员历史ID',
  'transferFundHistoryId': '汇款单历史ID',
  'withdrawBillHistoryId': '提款单历史ID',
  'withdrawBillId': '提款单ID',
  'withdrawBillNo': '提款单编号',
  'withdrawAccountTypeCode': '提现方式',
  'withdrawAccountName': '提现账号名称',
  'paypalAccount': 'PayPal账号',
  'withdrawTime': '提款时间',
  'withdrawMemo': '提款备注',
  'withdrawAmount': '提款金额',
  'shippingBillHistoryId': '物流单历史',
  'shippingBillStatus': '物流单状态',
  'deliveryMethodDesc': '交付方式',
  'deliveryMethodType': '交付方式',
  'saleOrderHistoryList': '订单历史管理',
  'skuDisplayList': 'SKU多语言展示管理',
  'languageList': '语言列表',
  'homeTemplateDisplayNo': '首页模板发布版本号',
  'homeTemplateName': '首页模板名称',
  'homeTemplateCode': '首页模板编码',
  'homeTemplateStatus': '首页模板状态',
  'homeTemplateId': '首页模板ID',
  'homeTemplateNo': '首页模板编号',
  'homeTemplateIntro': '首页模板说明',
  'bannerList': '轮播图一览',
  'linkTargetType': '连接目标类型',
  'picUrl': '图片URL',
  'couponList': '优惠券列表',
  'campaignList': '营销活动列表',
  'campaignDesc': '营销活动说明',
  'spuList': 'SPU列表',
  'approvalInfoList': '审批信息列表',
  'spuAttrDisplayList': 'SPU属性显示列表',
  'spuSaleAttrDisplayList': 'SPU销售属性显示列表',
  'guidPageUrl': '使用说明页面URL',
  'guideLinkPicUrl': '使用说明页面链接图片URL',
  'couponPicUrl': '优惠券链接图片URL',
  'getCouponPromptPicUrl': '获取优惠券提示图片URL',
  'guideLinkPicDisplay': '使用说明页面链接图片URL',
  'couponPicDisplay': '首页优惠券链接图片URL',
  'couponInDetailPicDisplay': '商品详情页优惠券链接图片URL',
  'getCouponPromptPicDisplay': '获取优惠券提示图片URL',
  'topMessageList': '置顶消息提示',
  'topPicList': '置顶可关闭图片提示',
  'topPicDisplayList': '置顶可关闭图片提示',
  'mobile': '手机',
  'balanceInfoList': '账户余额',
  'currentType': '货币类型',
  'totalBalance': '账户总额',
  'verificationCode': '提货码',
  'packageStatus': '包裹状态',
  'expiredPicUrl': '已过期图片',
  'usedPicUrl': '已使用图片',
  'seqNo': '序号',
  'approvalComment': '审批备注',
  'approvalTime': '审批时间',
  'approverName': '审批人',
  'approvalHistoryList': '审批历史',
  'orderSynStatus': '同步WMS状态',
  'directBuying': '直接购买'
}

const typeList = ['field', 'module', 'columnName']
for (let idx = 0; idx < typeList.length; idx++) {
  for (const field in fields) {
    locale[typeList[idx] + '.' + field] = fields[field]
    if (field.endsWith('Name') && typeList[idx] === 'page') {
      let key = typeList[idx] + '.' + field + 'List'
      key = key.replace('Name', '')
      locale[key] = fields[field].replace('名称', '') + '列表'
    } else if (field.endsWith('Id') && typeList[idx] === 'page') {
      let key = typeList[idx] + '.' + field + 'List'
      key = key.replace('Id', '')
      if (!locale[key]) {
        locale[key] = fields[field].replace('ID', '') + '列表'
      }
    }
  }
}
console.debug('locale', locale)
if (!window.localelist) {
  window.localelist = {}
}
window.localelist['zh-CN'] = locale
export default {
  ...components,
  ...locale
}
